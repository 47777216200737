<template>
  <div class="page-content-inner">
    <h3 class="mb-4">{{ $t('profile.profile') }}</h3>
    <div uk-grid>
      <div :class="user?.fullname?.length > 14 ? 'uk-width-2-6' : 'uk-width-1-5@m'">
        <div class="uk-card-default rounded text-center p-4">
          <div class="user-profile-photo m-auto">
            <img :src="user.photo_link" :alt="user.fullname">
          </div>
          <h4 class="mb-2 mt-3"> {{ user.fullname }}</h4>
          <p class="m-0">{{ user && user.title ? user.title.name : '-' }}</p>
        </div>
      </div>
      <div class="uk-width-expand@m">
        <div class="uk-card-default rounded">
          <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
            <h5 class="mb-0"><i class="icon-feather-user"></i> {{ $t('profile.personal_information') }}</h5>
            <a @click="$router.push({ name: 'user.update-profile' })" :uk-tooltip="$t('general.update_myinfo')">
              <i class="icon-feather-settings"></i> </a>
          </div>
          <hr class="m-0">
          <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.name') }} </h6>
              <p> {{ user.name }} </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.surname') }} </h6>
              <p> {{ user.surname }} </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.dob') }} </h6>
              <p>{{ user.dob }} </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.email') }} </h6>
              <p style="overflow:hidden;text-overflow: ellipsis">{{ user.email }}</p>
            </div>
            <div>
              <h6 class=" uk-text-bold"> {{ $t('profile.phone') }} </h6>
              <p>{{ user.phone }} </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.login_password') }} </h6>
              <p> ******** </p>
            </div>
          </div>
          <hr>
          <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.login_type') }} </h6>
              <p><i class="icon-feather-check"></i>{{ user && this.$t(user.login_type) ?
                this.$t('general.' + user.login_type?.key_name)
                :
                '-' }}</p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.authentication_type') }}</h6>
              <p><i class="icon-feather-check"></i>{{ user && user.authentication_type ?
                this.$t('general.' + user.authentication_type?.key_name)
                :
                '-' }}</p>
            </div>
          </div>
        </div>
        <div class="uk-card-default rounded mt-4">
          <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
            <h5 class="mb-0"><i class="icon-feather-briefcase"></i> {{ $t('profile.company_profile') }} </h5>
            <a @click="$router.push({ name: 'user.company-profile' })"
              uk-tooltip="title: Bilgilerimi Güncelle; pos: left" class="d-none"> <i class="icon-feather-settings"></i>
            </a>
          </div>
          <hr class="m-0">
          <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.company_name') }}</h6>
              <p>{{ user && user.company ? user.company?.name : '-' }}</p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.position') }} </h6>
              <p> {{ user && user.position ? user.position?.name : '-' }}</p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.title') }} </h6>
              <p> {{ user && user.title ? user.title?.name : '-' }}</p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.gm_region') }} </h6>
              <p> {{ user && user.region ? user.region?.name : '-' }} </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.unit') }} </h6>
              <p> {{ user && user.department ? user.department?.name : '-' }} </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.branch') }} </h6>
              <p> {{ user && user.branch ? user.branch?.name : '-' }} </p>
            </div>
          </div>
        </div>

        <div class="uk-card-default rounded mt-4">
          <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
            <h5 class="mb-0"><i class="uil-invoice"></i> Fatura Bilgileri </h5>
            <a @click="$router.push({ name: 'user.company-profile' })"
              uk-tooltip="title: Fatura Bilgilerini Güncelle; pos: left"> <i class="icon-feather-settings"></i>
            </a>
          </div>
          <hr class="m-0">
          <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
            <div>
              <h6 class="uk-text-bold"> Fatura Tipi</h6>
              <p>Kurumsal</p>
            </div>
            <div>
              <h6 class="uk-text-bold"> Firma Adı </h6>
              <p> Aktek Akademi Ltd. Şti.</p>
            </div>
            <div>
              <h6 class="uk-text-bold"> Vergi Dairesi </h6>
              <p> Mecidiyeköy </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> Vergi No </h6>
              <p> 21156542</p>
            </div>
            <div>
              <h6 class="uk-text-bold"> Adres </h6>
              <p>İmes Sanayi Sitesi B Blok 204.Sok. No:40 Y.Dudullu<br>
              İstanbul , Türkiye</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CURRENT_ITEM, GET_USER } from "@/core/services/store/user.module";
import module from "@/core/services/store/user.module";
import moment from "moment";
import store from "@/core/services";
export default {
  name: "Profile",
  data() {
    return {
      apiURL: 'api/user/self',
    };
  },
  computed: {
    loginLogo() {
      return process.env.BASE_URL + "media/images/anzera-logo-medium.png";
    },
    user: {
      get() {
        let user = store.getters[this.$options.name + '/' + CURRENT_ITEM]
        user.dob = user.dob ? moment(user.dob).format('YYYY-MM-DD') : '-'
        user.fullname = user.name + ' ' + user.surname
        return user
      },
      set(value) { }
    }
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(this.$options.name, module)
  },
  mounted() {
    this.$store.dispatch(this.$options.name + '/' + GET_USER, {
      url: this.apiURL,
      acceptPromise: true,
    })
  },
}
</script>

<style scoped></style>